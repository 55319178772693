// @flow
import React, { useState, useEffect, useRef, memo } from "react"
import isEqual from "lodash/isEqual"
import {
  Textbox,
  Icon,
  parseCountryCodeToText,
  checkValidRef,
} from "@lesmills/gatsby-theme-common"
import LocationDropdown from "./LocationDropdown"
import { MANUAL_ADDRESS_ID } from "./ManualAddressConstants"

type LookupErrorType = {|
  firstAddressError: string,
  cityError: string,
  stateError: string,
  postalCodeError: string,
|}

type PrismicData = {|
  manual_address_1: Array<LabelType>,
  manual_address_2: Array<LabelType>,
  manual_city: Array<LabelType>,
  manual_postal_code: Array<LabelType>,
  manual_state: Array<LabelType>,
  manual_country: Array<LabelType>,
|}

type Props = {|
  inline?: Boolean,
  currentCountry?: string,
  manualRef?: Object,
  error?: LookupErrorType,
  countries?: Array,
  placeDetails?: string,
  prismicData?: PrismicData,
  onChange?: () => void | Promise<void>,
  states?: Array,
  defaultZipCode?: string,
  isStateRequired?: Boolean,
|}

const ManualAddress = ({
  inline = false,
  countries = [],
  currentCountry = "",
  manualRef = {},
  data = {},
  error = {},
  prismicData = {},
  onChange = () => {},
  states = [],
  defaultZipCode = "",
  isStateRequired = false,
}: Props) => {
  // const [searchCountry, setSearchCountry] = useState("")
  // const [selectedCountry, setSelectedCountry] = useState("")
  const selectedCountry = parseCountryCodeToText(currentCountry, countries)
  const [selectedState, setSelectedState] = useState("")
  const { address, address2, state, city, postalCode } = data
  const [oldValues, setOldValues] = useState({
    [MANUAL_ADDRESS_ID.firstAddressId]: address,
    [MANUAL_ADDRESS_ID.stateId]: state,
    [MANUAL_ADDRESS_ID.postalCodeId]: postalCode,
    [MANUAL_ADDRESS_ID.cityId]: city,
  })
  const {
    firstAddressRef,
    secondsAddressRef,
    cityRef,
    postalCodeRef,
    stateRef,
    countryRef,
  } = manualRef

  const {
    manual_address_1 = {},
    manual_address_2 = {},
    manual_city = {},
    manual_postal_code = {},
    manual_state = {},
    manual_country = {},
  } = prismicData

  const textBoxClassnames = {
    wrapper: "w-full mb-0 lg:mb-0 pb-24",
    textbox: `w-full h-textbox-base md:h-textbox-lg md:text-2lg ${
      inline ? "lg:w-textbox-lg" : "lg:w-full"
    }`,
    label: "md:text-2lg",
  }
  const accordionStateRef = useRef(null)
  const filterStateRef = useRef(null)
  const accordionCountryRef = useRef(null)
  const [isExpanded, setIsExpanded] = useState({
    states: false,
    countries: false,
    searchState: "",
  })

  const isStatesExpanded = isExpanded.states
  // const isCountriesExpanded = isExpanded.countries

  const handleClickOutside = e => {
    if (checkValidRef(filterStateRef)) {
      filterStateRef.current.value = ""
    }

    // ignore clicks on the component itself
    if (
      e &&
      e.target &&
      ((checkValidRef(accordionStateRef) &&
        accordionStateRef.current.contains(e.target)) ||
        (checkValidRef(accordionCountryRef) &&
          accordionCountryRef.current.contains(e.target)))
    ) {
      return
    }
    setIsExpanded({
      states: false,
      countries: false,
      searchState: "",
    })
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    // Wait for change from response and override data selected in form
    if (data.state) {
      setSelectedState(data.state)
    }

    // Reset default value if data was changed
    if (
      checkValidRef(firstAddressRef) &&
      firstAddressRef.current.value !== address
    ) {
      firstAddressRef.current.value = address
    }
    if (
      checkValidRef(postalCodeRef) &&
      postalCodeRef.current.value !== postalCode
    ) {
      postalCodeRef.current.value = postalCode
    }
  }, [data])

  const handleOnChange = (e, oldValue, name) => {
    // Do nothing if the value is not changed
    if (e.target.value === oldValue) {
      return
    }

    setOldValues({
      ...oldValues,
      [name]: e.target.value,
    })

    onChange(e)
  }

  return (
    <>
      {/* Address */}
      <Textbox
        label={manual_address_1.text}
        classNames={textBoxClassnames}
        inputRef={firstAddressRef}
        defaultValue={address || ""}
        error={error.firstAddress}
        id={MANUAL_ADDRESS_ID.firstAddressId}
        handleOnBlur={e =>
          handleOnChange(
            e,
            oldValues[MANUAL_ADDRESS_ID.firstAddressId],
            MANUAL_ADDRESS_ID.firstAddressId
          )
        }
        testId={MANUAL_ADDRESS_ID.firstAddressId}
      />

      {/* Address 2 */}
      <Textbox
        label={manual_address_2.text}
        classNames={textBoxClassnames}
        inputRef={secondsAddressRef}
        defaultValue={address2 || ""}
        id={MANUAL_ADDRESS_ID.secondAddressId}
        testId={MANUAL_ADDRESS_ID.secondAddressId}
      />

      {/* City */}
      <Textbox
        label={manual_city.text}
        classNames={textBoxClassnames}
        inputRef={cityRef}
        defaultValue={city || ""}
        error={error.city}
        id={MANUAL_ADDRESS_ID.cityId}
        handleOnBlur={e =>
          handleOnChange(
            e,
            oldValues[MANUAL_ADDRESS_ID.cityId],
            MANUAL_ADDRESS_ID.cityId
          )
        }
        testId={MANUAL_ADDRESS_ID.cityId}
      />

      {/* Postal code (zip code) */}
      {!defaultZipCode && (
        <Textbox
          label={manual_postal_code.text}
          classNames={textBoxClassnames}
          inputRef={postalCodeRef}
          defaultValue={postalCode || ""}
          error={error.postalCode}
          id={MANUAL_ADDRESS_ID.postalCodeId}
          handleOnBlur={e =>
            handleOnChange(
              e,
              oldValues[MANUAL_ADDRESS_ID.postalCodeId],
              MANUAL_ADDRESS_ID.postalCodeId
            )
          }
          testId={MANUAL_ADDRESS_ID.postalCodeId}
        />
      )}
      {// Jira: https://lesmillsinternational.atlassian.net/browse/AB2B-340
      //  State is a dropdown for state-required countries
      isStateRequired ? (
        <div ref={accordionStateRef} className="w-full relative">
          {/* This textbox is used for only filter state, not contain selected state value */}
          <Textbox
            label={manual_state.text}
            classNames={{
              ...textBoxClassnames,
              wrapper: `${textBoxClassnames.wrapper} ${
                !isStatesExpanded ? "hidden" : ""
              }`,
            }}
            icon={
              <Icon
                type="base"
                icon="black-back"
                classNames="absolute md:top-15 right-10 top-10 w-6 rotate-180"
              />
            }
            handleOnChange={e => {
              // Only call when typing
              setIsExpanded({ ...isExpanded, searchState: e.target.value })
            }}
            defaultValue={selectedState || state || ""}
            id={"manual-filter-state"}
            inputRef={filterStateRef}
            error={error.state}
            testId={MANUAL_ADDRESS_ID.stateId}
          />
          <Textbox
            label={manual_state.text}
            classNames={{
              ...textBoxClassnames,
              wrapper: `${textBoxClassnames.wrapper} ${
                isStatesExpanded ? "hidden" : ""
              }`,
            }}
            icon={
              <Icon
                type="base"
                icon="black-back"
                classNames="absolute right-10 top-4 md:top-7 w-6 rotate-270"
              />
            }
            handleOnFocus={() => setIsExpanded({ states: !isStatesExpanded })}
            defaultValue={
              states.length === 0
                ? selectedCountry.name || ""
                : selectedState || state
            }
            inputRef={stateRef}
            id={MANUAL_ADDRESS_ID.stateId}
            error={error.state}
            testId={MANUAL_ADDRESS_ID.stateId}
          />
          {isStatesExpanded && states.length > 0 && (
            <LocationDropdown
              prismicData={states}
              searchTerm={isExpanded.searchState}
              handleClickOption={value => {
                stateRef.current.value = value
                filterStateRef.current.value = ""
                setIsExpanded({ states: !isStatesExpanded, searchState: "" })
                setSelectedState(value)
                handleOnChange(
                  {
                    target: {
                      getAttribute: () => MANUAL_ADDRESS_ID.stateId,
                      value,
                    },
                  },
                  selectedState,
                  MANUAL_ADDRESS_ID.stateId
                )
              }}
              labelField="label"
              valueField="label"
            />
          )}
        </div>
      ) : (
        <Textbox
          label={manual_state.text}
          classNames={textBoxClassnames}
          handleOnChange={e => {
            handleOnChange(
              e,
              oldValues[MANUAL_ADDRESS_ID.stateId],
              MANUAL_ADDRESS_ID.stateId
            )
          }}
          defaultValue={state || ""}
          id={MANUAL_ADDRESS_ID.stateId}
          inputRef={stateRef}
          error={error.state}
          testId={MANUAL_ADDRESS_ID.stateId}
        />
      )}
      {/* Country dropdown */}
      <div ref={accordionCountryRef} className="w-full relative">
        {/* Disable country dropdown for now - JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1038 */}
        <Textbox
          label={manual_country.text}
          classNames={textBoxClassnames}
          icon={
            <Icon
              type="base"
              icon="black-back"
              classNames="absolute right-10 top-4 md:top-7 w-6 rotate-270"
            />
          }
          // handleOnChange={e => {
          //   setSearchCountry(e.target.value)
          //   // onChange()
          //   setIsExpanded({ countries: !isCountriesExpanded })
          // }}
          // handleOnFocus={() => {
          //   setIsExpanded({ countries: !isCountriesExpanded })
          // }}
          // handleOnBlur={onChange}
          defaultValue={
            // selectedCountry ||
            parseCountryCodeToText(currentCountry, countries).name || ""
          }
          inputRef={countryRef}
          id={MANUAL_ADDRESS_ID.countryId}
          error={error.country}
          disabled={true}
          testId={MANUAL_ADDRESS_ID.countryId}
        />
        {/* Disable countries dropdown */}
        {/* {isCountriesExpanded && (
          <LocationDropdown
            prismicData={countries}
            searchTerm={searchCountry}
            handleClickOption={value => {
              countryRef.current.value = value
              countryRef.current.blur()
              setSelectedCountry(value)
              setIsExpanded({ countries: !isCountriesExpanded })
            }}
          />
        )} */}
      </div>
    </>
  )
}

export default memo(ManualAddress, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.lookupResult, nextProps.lookupResult) &&
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.error, nextProps.error) &&
    isEqual(prevProps.onChange, nextProps.onChange)
  )
})
