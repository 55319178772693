// @flow
import React, { memo } from "react"
import type { LabelType } from "@lesmills/gatsby-theme-common"
import { Button } from "@lesmills/gatsby-theme-common"
import FullAddress from "./FullAddress"
import ManualAddress from "./ManualAddress"
import MatchedResult from "./MatchedResult"

type PrismicData = {|
  billing_address_form_label: Array<LabelType>,
  full_address: Array<LabelType>,
  not_found_address_label: Array<LabelType>,
  body: Array<LabelType>,
|}

type Props = {|
  inline?: boolean,
  accordionRef?: () => void | Promise<void>,
  isExpanded?: boolean,
  onFocus?: () => void | Promise<void>,
  onSwitchManualForm?: () => void | Promise<void>,
  isShowManualForm?: boolean,
  error?: Object,
  manualRef?: Object,
  data: PrismicData,
  handleOnChange?: () => void | Promise<void>,
  handleSelectPlace?: () => void | Promise<void>,
  fullAddressRef?: () => void | Promise<void>,
  placeDetails?: Array,
  lookupResult?: Object,
  countries?: Array,
  states?: Array,
  defaultZipCode?: string,
  isStateRequired?: boolean,
|}

const BillingInformation = ({
  inline = false,
  accordionRef = () => {},
  isExpanded = false,
  onFocus = () => {},
  manualRef = {},
  data = {},
  fullAddressRef = () => {},
  handleOnChange = () => {},
  handleSelectPlace = () => {},
  placeDetails = {},
  lookupResult = {},
  error = {},
  onSwitchManualForm = () => {},
  isShowManualForm = false,
  countries = {},
  currentCountry = "",
  states = {},
  defaultZipCode = "",
  isStateRequired = false,
}: Props) => {
  const { billing_address_form_label = {}, show_address_details = {} } =
    data || {}
  return (
    <>
      <h4
        className="font-base-black text-gray-400 lg:text-2lg text-base mb-40 md:mb-35"
        data-cy="billing-info-title"
      >
        {billing_address_form_label.text}
      </h4>

      {isShowManualForm ? (
        <div
          className={`flex lg:justify-between mt-4 relative pb-5 ${
            inline ? "flex-col lg:w-billing-info-lg" : "flex-col w-full"
          }`}
        >
          <ManualAddress
            isManualInput
            countries={countries}
            onClick={onSwitchManualForm}
            currentCountry={currentCountry}
            manualRef={manualRef}
            error={error}
            data={placeDetails}
            lookupResult={lookupResult}
            prismicData={data}
            isShowManualForm={isShowManualForm}
            onChange={handleOnChange}
            states={states}
            defaultZipCode={defaultZipCode}
            isStateRequired={isStateRequired}
          />
          {!inline && (lookupResult || error.match) && (
            <MatchedResult error={error} />
          )}
        </div>
      ) : (
        <>
          <div
            className={`flex lg:justify-between mt-4 relative pb-5 ${
              inline
                ? "lg:flex-row flex-col lg:w-billing-info-lg"
                : "flex-col w-full"
            }`}
          >
            <FullAddress
              onSwitchManualForm={onSwitchManualForm}
              accordionRef={accordionRef}
              isExpanded={isExpanded}
              onFocus={onFocus}
              prismicData={data}
              fullAddressRef={fullAddressRef}
              onChange={handleOnChange}
              onSelectPlace={handleSelectPlace}
              data={placeDetails}
              lookupResult={lookupResult}
              error={error}
            />
          </div>
          <Button
            className="underline text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800 mb-20 md:mb-25 block"
            handleOnClick={onSwitchManualForm}
            testId="show-full-address-btn"
          >
            {show_address_details.text}
          </Button>
        </>
      )}
      {inline && (lookupResult || error.match) && (
        <MatchedResult error={error} />
      )}
    </>
  )
}

export default memo<Props>(BillingInformation)
