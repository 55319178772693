// @flow
import React, { memo } from "react"
import isEqual from "lodash/isEqual"

type Props = {|
  searchTerm?: () => void | Promise<void>,
  handleClickOption?: () => void | Promise<void>,
  prismicData?: Array,
  labelField?: string,
  valueField?: string,
|}

const LocationDropdown = ({
  prismicData = [],
  searchTerm = "",
  handleClickOption = () => {},
  labelField = "name",
  valueField = "name",
}: Props) => {
  return (
    <ul
      className={`cursor-pointer border-r border-l border-b border-gray-500 w-full absolute top-40 md:top-50 bg-white z-100 max-h-200 overflow-y-auto`}
    >
      {/* With empty key, the list should be showed all */}
      {(!searchTerm
        ? prismicData
        : prismicData.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
      ).map((item, index) => {
        return (
          <li
            key={`${item.value}-${index}`}
            onClick={() => {
              handleClickOption(item[valueField])
            }}
            className="py-15 pl-10 hover:bg-gray-100"
          >
            <p className="text-base font-base-light">{item[labelField]}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(LocationDropdown, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.prismicData, nextProps.prismicData) &&
    isEqual(prevProps.searchTerm, nextProps.searchTerm)
  )
})
