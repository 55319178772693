// @flow
import React, { useEffect, memo } from "react"
import isEqual from "lodash/isEqual"

import {
  Textbox,
  splitString,
  highlightKeyword,
} from "@lesmills/gatsby-theme-common"

type PrismicData = {|
  billing_address_form_label: Array<LabelType>,
  full_address: Array<LabelType>,
  not_found_address_label: Array<LabelType>,
  body: Array<LabelType>,
|}

type Props = {|
  inline: Boolean,
  accordionRef: () => void | Promise<void>,
  isExpanded?: Boolean,
  onFocus?: () => void | Promise<void>,
  onSwitchManualForm?: () => void | Promise<void>,
  prismicData: PrismicData,
  data: Object,
  onChange: () => void | Promise<void>,
  onSelectPlace: () => void | Promise<void>,
  fullAddressRef: () => void | Promise<void>,
  lookupResult?: Object,
  error: Object,
|}

const FullAddress = ({
  isExpanded = false,
  inline = false,
  accordionRef = () => {},
  onFocus = () => {},
  onSwitchManualForm = () => {},
  prismicData = {},
  fullAddressRef = () => {},
  onChange = () => {},
  onSelectPlace = () => {},
  data = {},
  lookupResult = {},
  error = {},
}: Props) => {
  const { full_address = {}, not_found_address_label = {} } = prismicData || {}
  const placeAutoComplete = lookupResult.placeAutoComplete || []

  useEffect(() => {
    // This will avoid the same result was picked and textbox didn't render again
    if (fullAddressRef && fullAddressRef.current) {
      fullAddressRef.current.value = data.result
    }
  }, [data])

  return (
    <div ref={accordionRef} className="w-full">
      <Textbox
        label={full_address.text}
        classNames={{
          wrapper: "w-full mb-32",
          textbox: `w-full h-textbox-base md:h-textbox-lg md:text-2lg truncate ${
            inline ? "lg:w-textbox-lg" : "lg:w-full"
          }`,
          label: "md:text-2lg",
        }}
        handleOnFocus={onFocus}
        testId="full-address"
        key={data.result}
        defaultValue={data.result}
        handleOnChange={onChange}
        inputRef={fullAddressRef}
        error={error.fullAddress}
        id="full-address"
      />
      {error.fullAddress || (!error.fullAddress && !isExpanded) ? null : (
        <ul
          className={`cursor-pointer border-r border-l border-b border-gray-500 w-full absolute top-40 md:top-50 bg-white z-100`}
        >
          <li
            className="text-gray-400 font-base-light text-3xs py-15 pl-10 hover:bg-gray-100"
            onClick={onSwitchManualForm}
            data-cy="not_found_address_label"
          >
            <p>{not_found_address_label.text}</p>
          </li>
          {placeAutoComplete.map(item => {
            const value = item.description
            const placeId = item.place_id
            const fullAddressDesc = splitString(item.description)
            const fullAddressDescLength = fullAddressDesc.length

            return (
              <li
                key={placeId}
                onClick={() => onSelectPlace(placeId, value)}
                className="py-15 pl-10 hover:bg-gray-100"
              >
                <p className="text-base font-base-light">
                  {highlightKeyword(
                    fullAddressRef.current.value,
                    fullAddressDescLength > 0 ? fullAddressDesc[0] : ""
                  )}
                </p>
                {fullAddressDescLength > 1 && (
                  <span className="text-gray-800 text-xs font-base-light font-light leading-7none">
                    {fullAddressDesc[1]}
                  </span>
                )}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default memo(FullAddress, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.lookupResult, nextProps.lookupResult) &&
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.onSwitchManualForm, nextProps.onSwitchManualForm) &&
    isEqual(prevProps.error, nextProps.error)
  )
})
