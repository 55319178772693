// @flow
import React, { memo } from "react"

type LookupErrorType = {|
  fullAddress: string,
  postalCode: string,
  match: string,
  state: string,
  city: string,
  country: string,
|}

type MatchedResultProps = {|
  error?: LookupErrorType,
|}

const MatchedResult = ({ error }: MatchedResultProps) => (
  <p
    className={`lg:mt-10 -mt-20 font-base-light lg:text-2lg text-3xs text-error`}
  >
    <span>{error.match}</span>
  </p>
)

export default memo<MatchedResultProps>(MatchedResult)
